import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderInner from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SuccessContent from "@/components/SuccessContent";

const Success = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Obrigado pelo seu interesse">
          <HeaderInner />
            <div style={{ marginTop: 60, textAlign: 'center', background: "#f6f7fb" }}><iframe src="https://forms.monday.com/forms/embed/0613bbe3dcdedf9942fa09453cafa772?r=use1" width="750" height="1300" /></div>
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Success;
